@import 'abstracts/fonts',
'abstracts/variables';

@import '~@flashpointbv/solar-ui-core/src/scss/solar-ui-core.scss';
@import 'swiper/css/swiper.css';

@import 'abstracts/functions',
'abstracts/mixins',
'abstracts/animations';

@import 'base/base',
'base/forms';

@import 'layout/header',
'layout/footer',
'layout/navigation';

@import 'components/list-item',
'components/breadcrumbs',
'components/filters',
'components/slider-controls',
'components/related-items',
'components/modal',
'components/placeholders',
'components/product-compare',
'components/pagination',
'components/home-slider',
'components/autocomplete',
'components/messages';

@import 'pages/home',
'pages/customer',
'pages/cart',
'pages/checkout',
'pages/storelocator',
'pages/category',
'pages/category-landing',
'pages/cms',
'pages/contact',
'pages/compare',
'pages/product',
'pages/blog',
'pages/success';
