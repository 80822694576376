.global__message {
    font-size: 1.4rem;
    line-height: 2rem;
    display: inline-block;

    &.--block {
        display: block;
    }

    &.--margin {
        margin: 1rem 0;
    }

    &.--margin--top {
        margin: 1rem 0 0;
    }

    &.--margin--bottom {
        margin: 0 0 1rem;
    }

    &.--full-width {
        width: 100%;
    }

    &.--space {
        padding: 1rem 1.5rem;
    }

    @each $messageStyle in map-keys(map-get($color-palette, alert)) {
        &.--#{to-lower-case($messageStyle)} {
            color: map-get(map-get($color-palette, alert), $messageStyle);
        }
    }

    &--bg {
        @extend .global__message;
        padding: .5rem 1rem;
        @each $messageStyle in map-keys(map-get($color-palette, alert)) {
            &.--#{to-lower-case($messageStyle)} {
                background-color: map-get(map-get($color-palette, AlertBackgrounds), $messageStyle);
                border: .1rem solid lighten(map-get(map-get($color-palette, alert), $messageStyle), 30%);
            }
        }
    }

    p {
        font-size: 1.4rem;
        line-height: 2rem;
        margin: 0;
    }

    a {
        font-size: 1.4rem;
        line-height: 2rem;
        text-decoration: underline;
        color: inherit;
    }
}
