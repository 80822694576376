.checkout-cart_body {
    ::placeholder {
        color: color(gray, Gray-400);
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: color(gray, Gray-400);
    }

    ::-ms-input-placeholder {
        color: color(gray, Gray-400);
    }

    &.--checkout header {
        .mobile__menu, .header__nav, .header--right {
            display: none ;
        }
        .--logo {
            padding-right: 0;
        }
    }
    .checkout-cart {
        .progress-bar {
            display: flex;
            gap: 1rem;
            margin: 2rem 0;
            li {
                flex: 1;
                span {
                    display: block;
                    background-color: color(gray, Gray-100);
                    padding: 0 .8rem;
                    font-size: 1rem;
                    line-height: 4rem;
                    text-transform: uppercase;
                    font-weight: 600;
                    background-repeat: no-repeat;
                    background-position: right .5rem center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    @include breakpoint(medium) {
                        padding: 0 2rem;
                        font-size: 1.3rem;
                        background-position: right 1.5rem center;
                    }
                }
                &:not(:last-child):not(.--active) {
                    span {
                        cursor: pointer;
                    }
                }
                &.--active {
                    span {
                        background-color: color(base, Secondary);
                        color: color(base, Primary);
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Primary)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                    }
                }
                &.--complete {
                    span {
                        background-color: color(base, Secondary);
                        color: color(base, Primary);
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");
                        background-size: 2rem;
                    }
                }
            }
        }
        .checkout-error-container {
            margin: 0 0 1rem;
        }
        .login__block {
            background: color(gray, Gray-100);
            padding: 2rem;
            margin: 0 0 2rem;
            display: flex;
            > span {
                color: color(gray, Gray-400);
            }
            > button {
                cursor: pointer;
                color: color(gray, Gray-400);
                margin-left: auto;
                text-decoration: underline;
                font-size: 1.4rem;
            }
            ._remind {
                @include hover() {
                    text-decoration: underline;
                }
            }
        }
        .form-field-container {
            @include breakpoint(medium) {
                width: 75%;
            }
        }
        .field__group {
            @include breakpoint(medium) {
                &.names {
                    > div {
                        flex: 1;
                    }
                }
            }
            .checkout__field {
                flex: 1;
                input {
                    height: 4.4rem;
                    line-height: 4.4rem;
                    width: 100%;
                    &.failed {
                        border-color: color(alert, Error);
                    }
                    &.valid:not(.empty) {
                        border-color: color(alert, Success);
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='6.13132462 8.81921546 3.04945992 5.73735077 2 6.77942013 6.13132462 10.9107447 15 2.04206936 13.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23178038' stroke-width='1.5' fill='%23178038' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-position: right 10px center;
                        background-size: 12px;
                    }
                    &[type=radio] {
                        appearance: auto;
                        width: unset;
                        height: unset;
                    }
                }
                select {
                    width: 100%;
                    height: 4.4rem;
                    line-height: 4.4rem;
                }
            }
            margin: 1.5rem -.5rem 1rem;
            display: flex;
            > div {
                margin: 0 .5rem;
            }
        }
        .shipping__address, .billing__address {
            &.billing__address {
                margin: 3rem 0 0;
            }
            h2 {
                border-top: .2rem solid color(gray, Gray-100);
                text-transform: uppercase;
                font-size: 1.8rem;
                line-height: normal;
                padding: 2rem 0 1rem;
            }
            .shipping-address-select {
                margin: 0 0 3rem;
                label {
                    margin: 0 0 1rem;
                }
                @include breakpoint(medium) {
                    select {
                        width: 65%;
                        padding-right: 3rem;
                        background-position: right -2rem top 1.5rem;
                    }
                }
            }
            .checkout__address-autocomplete_result {
                @include autocomplete;
            }
            .billing-same-as-shipping-control {
                display: flex;
                align-items: center;
                label:not(.vue-js-switch) {
                    margin-left: 1rem;
                    font-size: 1.4rem;
                }
            }
        }
        .billing__address {
            .shipping-address-select {
                margin: 3rem 0;
            }
        }
        .checkout-footer {
            margin: 3rem 0;
            ._place-order {
                @include breakpoint(medium) {
                    display: grid;
                    grid-auto-columns: max-content;
                }
                a {
                    font-size: 1.2rem;
                    margin: .5rem 0 0;
                    span {
                        font-size: 1.2rem;
                        text-decoration: underline;
                        font-weight: normal;
                    }
                }
            }
            button {
                min-width: 40%;
                @include breakpoint(small only) {
                    width: 100%;
                }
            }
        }
        .cart__side-bar-container .cart__side-bar ._actions {
            button {
                width: 100%;
            }
        }
        .payment__methods {
            position: relative;
            padding: 1rem 0 0;
            h2 {
                text-transform: uppercase;
                font-size: 1.8rem;
                line-height: normal;
                margin: 0 0 3rem;
            }
            ._loading {
                position: static;
                background: transparent;
                ._spinner {
                    margin: 2rem 0;
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
            .payment__methods-list {
                @include breakpoint(medium) {
                    width: 70%;
                }
                .payment__method {
                    input {
                        position: absolute;
                        visibility: hidden;
                        &:checked + label {
                            background-color: color(gray, Gray-100);
                            border-color: color(gray, Gray-200);
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='6.13132462 8.81921546 3.04945992 5.73735077 2 6.77942013 6.13132462 10.9107447 15 2.04206936 13.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23178038' stroke-width='1.5' fill='%23178038' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: right 2rem center;
                        }
                    }
                    label {
                        display: flex;
                        align-items: center;
                        border: .1rem solid color(gray, Gray-100);
                        padding: 1rem;
                        cursor: pointer;
                        margin: 2rem 0;
                        img {
                            aspect-ratio: 8/7;
                            margin-right: 1rem;
                        }
                        span._title {
                            display: block;
                            font-weight: normal;
                            font-size: 1.6rem;
                        }
                        span._fee {
                            display: block;
                            font-weight: normal;
                            color: color(alert, Success);
                            margin-left: 5px;
                            font-size: 12px;
                            line-height: 16px;
                        }
                        @include breakpoint(medium) {
                            @include transition;
                            &:hover {
                                border-color: color(gray, Gray-200);
                            }
                        }
                    }
                }
            }
            .payment__method__options {
                border-bottom: .2rem solid color(gray, Gray-100);
                padding: 0 0 2rem;
            }
        }
        .address__summary-container {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 2px solid color(gray, Gray-100);
            padding: 0 0 3rem;
            @include breakpoint(small only) {
                padding: 1.5rem 0 2rem;
            }
            .address__summary {
                flex: 1;
                h4 {
                    font-family: $default-font;
                    text-transform: uppercase;
                    font-weight: normal;
                    font-size: 1.4rem;
                }
                ul {
                    margin: 0;
                    li {
                        line-height: 2.2rem;
                        font-size: 1.4rem;
                    }
                }
            }
            button {
                cursor: pointer;
                width: 100%;
                text-align: left;
                margin: 2rem 0 0;
                color: color(gray, Gray-300);
                font-size: 1.4rem;
                @include breakpoint(medium) {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .cart__side-bar-container {
        .payment__methods {
            p {
                display: flex;
            }
            img {
                margin-right: .5rem;
            }
        }
    }
    .checkout-footer, ._actions {
        button._cart-loading {
            background-image: url("data:image/svg+xml,%0A%3C!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL --%3E%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='8.042%25' y1='0%25' x2='65.682%25' y2='23.865%25' id='a'%3E%3Cstop stop-color='#{color(base, Secondary)}' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='#{color(base, Secondary)}' stop-opacity='.631' offset='63.146%25'/%3E%3Cstop stop-color='#{color(base, Secondary)}' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(%23a)' stroke-width='2'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/path%3E%3Ccircle fill='#{color(base, Secondary)}' cx='36' cy='18' r='1'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: left 1.5rem center;
            background-size: 2rem;
        }
    }
    .checkout__newsletter {
        margin: 3rem 0 0;
        padding: 3rem 0 0;
        border-top: 2px solid color(gray, Gray-100);
        display: flex;
        align-items: center;
        @include breakpoint(medium) {
            width: 70%;
        }
        label:last-child {
            margin-left: 1rem;
        }
    }
    .vfl-has-label {
        .vfl-label-on-input {
            top: -1rem;
            right: auto;
            padding: 0 .5rem;
            left: .5rem;
            background: color(base, White);
            color: color(base, Secondary);
            font-size: 1.1rem;
            font-weight: 500;
        }
    }
}
