.catalog-product-page {
    .breadcrumbs {
        border-bottom: .1rem solid color(gray, Gray-200);
        margin: 0 0 4rem;
        padding: 2rem 0;
        @include breakpoint(small only) {
            display: none;
        }
    }
    .product__view {
        h1 {
            font-size: 2.4rem;
        }
        h3 {
            text-transform: uppercase;
            text-align: center;
            margin: 0 0 4rem;
            font-size: 2rem;
            @include breakpoint(medium) {
                font-size: 3.2rem;
                margin: 0 0 2rem;
            }
        }
        h4 {
            font-size: 2rem;
            line-height: 3.8rem;
            font-family: $secondary-font;
            font-weight: normal;
            text-transform: uppercase;
            margin: 0 0 2rem;
        }
        .rating-container {
            display: flex;
            align-items: center;
            margin: 2rem 0 0;
            .--rating {
                cursor: pointer;
                display: flex;
                width: 15.2rem;
                height: 2.5rem;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='152px' height='25px' viewBox='0 0 152 25' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(-0.000000, 0.000000)' fill='#{color(gray, Gray-100)}'%3E%3Cpolygon id='Fill-1' points='13.1210652 0 10.0232952 9.54944493 2.79238565e-14 9.54944493 8.10941762 15.4515392 5.0116476 25 13.1210652 19.0988899 21.2304828 25 18.1327128 15.4515392 26.2421304 9.54944493 16.2188352 9.54944493'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy' points='44.5605326 0 41.4627626 9.54944493 31.4394674 9.54944493 39.548885 15.4515392 36.451115 25 44.5605326 19.0988899 52.6699502 25 49.5721802 15.4515392 57.6815978 9.54944493 47.6583026 9.54944493'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-2' points='76 0 72.90223 9.54944493 62.8789348 9.54944493 70.9883524 15.4515392 67.8905824 25 76 19.0988899 84.1094176 25 81.0116476 15.4515392 89.1210652 9.54944493 79.09777 9.54944493'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-3' points='107.439467 0 104.341697 9.54944493 94.3184022 9.54944493 102.42782 15.4515392 99.3300498 25 107.439467 19.0988899 115.548885 25 112.451115 15.4515392 120.560533 9.54944493 110.537237 9.54944493'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-4' points='138.878935 0 135.781165 9.54944493 125.75787 9.54944493 133.867287 15.4515392 130.769517 25 138.878935 19.0988899 146.988352 25 143.890582 15.4515392 152 9.54944493 141.976705 9.54944493'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                > span {
                    display: block;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='152px' height='25px' viewBox='0 0 152 25' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(-0.000000, 0.000000)' fill='#{color(base, Primary)}'%3E%3Cpolygon id='Fill-1' points='13.1210652 0 10.0232952 9.54944493 2.79238565e-14 9.54944493 8.10941762 15.4515392 5.0116476 25 13.1210652 19.0988899 21.2304828 25 18.1327128 15.4515392 26.2421304 9.54944493 16.2188352 9.54944493'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy' points='44.5605326 0 41.4627626 9.54944493 31.4394674 9.54944493 39.548885 15.4515392 36.451115 25 44.5605326 19.0988899 52.6699502 25 49.5721802 15.4515392 57.6815978 9.54944493 47.6583026 9.54944493'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-2' points='76 0 72.90223 9.54944493 62.8789348 9.54944493 70.9883524 15.4515392 67.8905824 25 76 19.0988899 84.1094176 25 81.0116476 15.4515392 89.1210652 9.54944493 79.09777 9.54944493'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-3' points='107.439467 0 104.341697 9.54944493 94.3184022 9.54944493 102.42782 15.4515392 99.3300498 25 107.439467 19.0988899 115.548885 25 112.451115 15.4515392 120.560533 9.54944493 110.537237 9.54944493'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-4' points='138.878935 0 135.781165 9.54944493 125.75787 9.54944493 133.867287 15.4515392 130.769517 25 138.878935 19.0988899 146.988352 25 143.890582 15.4515392 152 9.54944493 141.976705 9.54944493'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    height: 2.5rem;
                }
            }
            a {
                margin-left: auto;
                color: color(gray, Gray-200);
                text-decoration: underline;
                font-size: 1.5rem;
                line-height: 2.2rem;
            }
        }
        .out-of-stock--message {
            margin: 2rem 0 0;
        }
        .product__configurable {
            display: flex;
            flex-flow: column;
            gap: 2rem;
            margin-bottom: 1rem;
            dl {
                dt {
                    font-size: 1.6rem;
                    margin-bottom: .5rem;
                    font-weight: 500;
                }
                dd {
                    &.swatch__holder {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        gap: 1.5rem;
                        label {
                            margin: 0.1rem;
                            border: 0.1rem solid color(gray, Gray-200);
                            background: color(gray, Gray-100);
                            cursor: pointer;
                            input[type="radio"] {
                                position: absolute;
                                left: -99999rem;
                                opacity: 0;
                            }
                            img {
                                aspect-ratio: 1;
                                object-fit: scale-down;
                            }
                            &._active {
                                border-width: 0.2rem;
                                border-color: color(base, Secondary);
                            }
                        }
                    }
                    select {
                        min-width: 50%;
                        width: 100%;
                        font-size: 1.6rem;
                        height: 4rem;
                        @include breakpoint(medium) {
                            width: auto;
                        }
                    }
                    .product__configurable-option__row {
                        display: flex;
                        gap: 1rem;
                        flex-wrap: wrap;
                        .product__configurable-option__item {
                            background: color(gray, Gray-100);
                            padding: 0.5rem 1rem;
                            font-size: clamp(1.2rem,1vw,1.4rem);
                            cursor: pointer;
                            line-height: 2.2rem;
                            font-weight: 500;
                            min-width: 6rem;
                            text-align: center;
                            border: 0.1rem solid transparent;
                            transition: all .2s ease;
                            &:hover {
                                background: color(gray, Gray-200);
                            }
                            &.--disabled {
                                opacity: .35;
                                color: color(gray, Gray-400);
                                pointer-events: none;
                            }
                            &.--selected {
                                border-color: color(base, Secondary);
                            }
                        }
                    }
                }
            }
            .option-list{
                li{
                    font-size:1.6rem;
                    border-bottom: .1rem solid color(base, Black);
                    button{
                        padding:1.2rem 1.6rem;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        &:disabled{
                            pointer-events: none;
                        }
                    }
                }
            }
            .option-select--mobile{
                ._button{
                    width:100%;
                }
            }
        }
        .family__products {
            margin: 3.5rem 0;
            .--attribute {
                &:not(:last-child) {
                    margin: 0 0 2.5rem;
                }
                > span {
                    display: block;
                    margin: 0 0 1rem;
                    font-weight: 500;
                    font-size: 1.5rem;
                }
                .--products {
                    display: flex;
                    flex-flow: wrap;
                    gap: 1rem;
                    &.--color {
                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 20%;
                            border: .1rem solid color(gray, Gray-200);
                            aspect-ratio: 1;
                            max-width: 7.6rem;
                            background-color: color(gray, Gray-100);
                            padding: .1rem;
                            @include hover() {
                                border-color: color(base, Secondary);
                            }
                            &.--active {
                                padding: 0;
                                border-color: color(base, Secondary);
                                border-width: .2rem;
                                pointer-events: none;
                            }
                            &.--out-of-stock {
                                position: relative;
                                    &:before {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        left: 0;
                                        z-index: 1;
                                        display: block;
                                        content: "";
                                        background-image: linear-gradient(to bottom right, #b3b3ba 25%, transparent 25%, transparent 50%, #b3b3ba 50%, #b3b3ba 75%, transparent 75%, transparent);
                                        background-size: 4px 4px;
                                        opacity: .5;
                                    }
                            }
                        }
                    }
                    &:not(.--color) {
                        a {
                            min-width: 16.666666%;
                            border: .1rem solid color(gray, Gray-200);
                            padding: .1rem;
                            > span > span {
                                padding: 0 1rem;
                                display: block;
                                font-size: 1.4rem;
                                line-height: 4.4rem;
                                font-weight: 500;
                                text-align: center;
                            }
                            @include hover() {
                                border-color: color(base, Secondary);
                            }
                            &.--active {
                                pointer-events: none;
                                border: .2rem solid color(base, Secondary);
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
        .product__price-container {
            margin: 2rem 0 0;
            .product__price {
                display: flex;
                gap: .5rem;
                align-items: center;
                > span {
                    font-size: 2.2rem;
                    line-height: 3rem;
                    font-weight: normal;
                    &.--old {
                        text-decoration: line-through;
                        color: color(gray, Gray-400);
                    }
                }
            }
        }
        .product-usp {
            margin: 2rem 0 0;
        }
        .top__description {
            .short__description {
                margin: 2rem 0 0;
                p {
                    font-size: 1.5rem;
                    line-height: 2.2rem;
                }
                a {
                    font-size: 1.5rem;
                    line-height: 2.2rem;
                    text-decoration: underline;
                }
            }
        }
        .product__configuration {
            margin: 2rem 0 0;
            .product__add-to-cart-container {
                display: flex;
                gap: 2rem;
                .product__qty {
                    flex: 0 0 clamp(8rem, 7vw, 10.8rem);
                    position: relative;
                    button {
                        position: absolute;
                        font-size: 2rem;
                        line-height: 4rem;
                        left: 0;
                        cursor: pointer;
                        padding: 0 1rem;
                        top: 0;
                        &:last-of-type {
                            right: 0;
                            left: auto;
                        }
                        &:disabled {
                            cursor: not-allowed;
                            color: color(gray, Gray-200);
                        }
                    }
                    input {
                        height: 4rem;
                        font-family: $secondary-font;
                        font-size: 1.2rem;
                        text-align: center;
                        width: 100%;
                    }
                }
                .add-to-cart__holder {
                    flex: 1;
                    button {
                        width: 100%;
                        font-size: clamp(1.2rem, 1vw, 1.4rem);
                        padding: .5rem 1rem;
                        &._cart-loading {
                            border-bottom: .1rem solid transparent;
                        }
                    }
                }
                .toggle__wishlist {
                    button {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Secondary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z' /%3E%3C/svg%3E%0A");
                        background-size: 2.4rem;
                        background-position: 50%;
                        background-repeat: no-repeat;
                        height: 4rem;
                        width: 3rem;
                        cursor: pointer;
                        transition: all .2s ease;
                    }
                    &.--active {
                        button {
                            animation-name: wishlist-animation;
                            animation-duration: .8s;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{color(base, Primary)}' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Secondary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z' /%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }
            .product__compare {
                font-size: 1.4rem;
                margin: 2rem 0 0;
                display: flex;
                justify-content: flex-start;
            }
            .add-to-cart__message {
                margin: 1rem 0 0;
            }
            .off-canvas__upsell {
                position: relative;
                .--products {
                    h3, h4 {
                        font-size: 1.6rem;
                        margin: 2rem 0;
                        padding: 0 1rem 0 2.5rem;
                        text-transform: none;
                        text-align: left;
                        @include breakpoint(small only) {
                            font-size: 1.4rem;
                        }
                    }
                    a {
                        display: flex;
                        border-bottom: .1rem solid color(gray, Gray-100);
                        gap: 1rem;
                        padding: 1rem;
                        h4 {
                            line-height: normal;
                            font-family: 'Laslo', sans-serif;
                            padding: 0;
                            margin: 0;
                        }
                        .price__holder {
                            display: flex;
                            gap: 1rem;
                            span {
                                &.--special {
                                    font-weight: 900;
                                }
                                &.--old {
                                    text-decoration: line-through;
                                }
                            }
                        }
                        .add-to-cart__container {
                            display: block;
                            margin-left: auto;
                            margin-top: auto;
                            button {
                                background-color: color(base, Primary);
                                width: 4rem;
                                height: 4rem;
                                margin-left: auto;
                                margin-top: auto;
                                text-indent: -9999rem;
                                cursor: pointer;
                                background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='20px' viewBox='0 0 24 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-a6pc3d_voh-1' points='0 -1.77635684e-15 3.589 -1.77635684e-15 3.589 3.5896 0 3.5896'%3E%3C/polygon%3E%3Cpolygon id='path-a6pc3d_voh-3' points='0 -1.77635684e-15 3.589 -1.77635684e-15 3.589 3.5896 0 3.5896'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-9' transform='translate(1.000000, 1.000000)'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' points='0 0 2.98 0 6.377 12.476 18.73 11.056 22.065 3.335 9.589 3.335'%3E%3C/polyline%3E%3Cg id='Group-5' transform='translate(4.703000, 15.371600)'%3E%3Cmask id='mask-a6pc3d_voh-2' fill='white'%3E%3Cuse xlink:href='%23path-a6pc3d_voh-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Clip-4'%3E%3C/g%3E%3Cpath d='M3.589,1.795 C3.589,2.786 2.786,3.59 1.794,3.59 C0.803,3.59 0,2.786 0,1.795 C0,0.804 0.803,-1.77635684e-15 1.794,-1.77635684e-15 C2.786,-1.77635684e-15 3.589,0.804 3.589,1.795' id='Fill-3' fill='#{color(base, Secondary)}' mask='url(%23mask-a6pc3d_voh-2)'%3E%3C/path%3E%3C/g%3E%3Cg id='Group-8' transform='translate(16.376100, 15.371600)'%3E%3Cmask id='mask-a6pc3d_voh-4' fill='white'%3E%3Cuse xlink:href='%23path-a6pc3d_voh-3'%3E%3C/use%3E%3C/mask%3E%3Cg id='Clip-7'%3E%3C/g%3E%3Cpath d='M3.589,1.795 C3.589,2.786 2.786,3.59 1.794,3.59 C0.803,3.59 0,2.786 0,1.795 C0,0.804 0.803,-1.77635684e-15 1.794,-1.77635684e-15 C2.786,-1.77635684e-15 3.589,0.804 3.589,1.795' id='Fill-6' fill='#{color(base, Secondary)}' mask='url(%23mask-a6pc3d_voh-4)'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                background-repeat: no-repeat;
                                background-position: center;
                                @include hover() {
                                    background-color: color(base, PrimaryHover);
                                }
                                &:disabled {
                                    background: color(gray, Gray-100);
                                    cursor: not-allowed;
                                }
                                &.--loading-cart {
                                    background-image: url("data:image/svg+xml,%0A%3C!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL --%3E%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='8.042%25' y1='0%25' x2='65.682%25' y2='23.865%25' id='a'%3E%3Cstop stop-color='#{color(base, Secondary)}' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='#{color(base, Secondary)}' stop-opacity='.631' offset='63.146%25'/%3E%3Cstop stop-color='#{color(base, Secondary)}' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(%23a)' stroke-width='2'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/path%3E%3Ccircle fill='#{color(base, Secondary)}' cx='36' cy='18' r='1'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                                    background-size: 2rem;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                }
                                &.--added-cart {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(alert, Success)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M5 12l5 5l10 -10' /%3E%3C/svg%3E");
                                    background-size: 2rem;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                }
                            }
                        }
                    }
                }
            }
        }
        .product__detail {
            margin: 2rem 0 0;
            h4 {
                font-size: 2.1rem;
                font-weight: 600;
                margin: 0 0 1rem;
                text-transform: unset;
                font-family: $default-font;
            }
            dl {
                border-bottom: .1rem solid color(gray, Gray-200);
                dt {
                    cursor: pointer;
                    font-size: 1.6rem;
                    line-height: 4.6rem;
                    border-top: .1rem solid color(gray, Gray-200);
                    font-weight: 400;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 4.330010) rotate(-270.000000) translate(-5.306354, -4.330010) ' points='2.44757827 -0.169989964 8.16998996 4.3284815 2.44271724 8.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: right center;
                    &.--active {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='9px' viewBox='0 0 11 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Secondary)}' stroke-width='2' transform='translate(5.306354, 5.330010) rotate(-90.000000) translate(-5.306354, -5.330010) ' points='2.44757827 0.830010036 8.16998996 5.3284815 2.44271724 9.83001004'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                    }
                }
                dd {
                    display: none;
                    padding: .5rem 0 1.5rem;
                    &.--visible {
                        display: block;
                    }
                    &.--description {
                        p:not(:last-child) {
                            margin: 0 0 2rem;
                        }
                        p b {
                            font-weight: 700;
                        }
                    }
                    ul {
                        font-size: 1.4rem;
                        line-height: 2rem;
                        margin: 0 0 1rem;
                        &:not(.--spec) {
                            list-style: disc outside;
                            padding-left: 1.2rem;
                        }
                        &.--spec {
                            li {
                                display: flex;
                                gap: 1rem;
                                align-items: flex-start;
                                span {
                                    padding: .2rem 0;
                                    &:first-child {
                                        flex: 0 0 20rem;
                                    }
                                    flex: 1;
                                }
                            }
                        }
                    }
                    &.product__reviews {
                        .product__review {
                            padding: 1rem 0;
                            &:not(:last-child) {
                                border-bottom: .1rem solid color(grey, Gray-100);
                            }
                            &:first-child {
                                padding: 0 0 1rem;
                            }
                            .--header {
                                display: flex;
                                align-items: center;
                                gap: 1rem;
                                margin: 0 0 .5rem;
                                h5 {
                                    font-size: 1.5rem;
                                    font-weight: 700;
                                    line-height: 1.9rem;
                                }
                                .--rating {
                                    flex: 0 0 8.5rem;
                                    height: 1.4rem;
                                    margin-left: auto;
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='85px' height='14px' viewBox='0 0 85 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(-0.000000, 0.000000)' fill='#{color(gray, Gray-100)}'%3E%3Cpolygon id='Fill-1' points='7.33743779 0 5.60513218 5.34768916 1.56153145e-14 5.34768916 4.5348717 8.65286198 2.80256609 14 7.33743779 10.6953783 11.8723095 14 10.1400039 8.65286198 14.6748756 5.34768916 9.06974339 5.34768916'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy' points='24.9187189 0 23.1864133 5.34768916 17.5812811 5.34768916 22.1161528 8.65286198 20.3838472 14 24.9187189 10.6953783 29.4535906 14 27.721285 8.65286198 32.2561567 5.34768916 26.6510245 5.34768916'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-2' points='42.5 0 40.7676944 5.34768916 35.1625622 5.34768916 39.6974339 8.65286198 37.9651283 14 42.5 10.6953783 47.0348717 14 45.3025661 8.65286198 49.8374378 5.34768916 44.2323056 5.34768916'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-3' points='60.0812811 0 58.3489755 5.34768916 52.7438433 5.34768916 57.278715 8.65286198 55.5464094 14 60.0812811 10.6953783 64.6161528 14 62.8838472 8.65286198 67.4187189 5.34768916 61.8135867 5.34768916'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-4' points='77.6625622 0 75.9302566 5.34768916 70.3251244 5.34768916 74.8599961 8.65286198 73.1276905 14 77.6625622 10.6953783 82.1974339 14 80.4651283 8.65286198 85 5.34768916 79.3948678 5.34768916'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                    > span {
                                        display: block;
                                        height: 1.4rem;
                                        background-image: url("data:image/svg+xml,%0A%3Csvg width='85px' height='14px' viewBox='0 0 85 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(-0.000000, 0.000000)' fill='#{color(base, Primary)}'%3E%3Cpolygon id='Fill-1' points='7.33743779 0 5.60513218 5.34768916 1.56153145e-14 5.34768916 4.5348717 8.65286198 2.80256609 14 7.33743779 10.6953783 11.8723095 14 10.1400039 8.65286198 14.6748756 5.34768916 9.06974339 5.34768916'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy' points='24.9187189 0 23.1864133 5.34768916 17.5812811 5.34768916 22.1161528 8.65286198 20.3838472 14 24.9187189 10.6953783 29.4535906 14 27.721285 8.65286198 32.2561567 5.34768916 26.6510245 5.34768916'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-2' points='42.5 0 40.7676944 5.34768916 35.1625622 5.34768916 39.6974339 8.65286198 37.9651283 14 42.5 10.6953783 47.0348717 14 45.3025661 8.65286198 49.8374378 5.34768916 44.2323056 5.34768916'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-3' points='60.0812811 0 58.3489755 5.34768916 52.7438433 5.34768916 57.278715 8.65286198 55.5464094 14 60.0812811 10.6953783 64.6161528 14 62.8838472 8.65286198 67.4187189 5.34768916 61.8135867 5.34768916'%3E%3C/polygon%3E%3Cpolygon id='Fill-1-Copy-4' points='77.6625622 0 75.9302566 5.34768916 70.3251244 5.34768916 74.8599961 8.65286198 73.1276905 14 77.6625622 10.6953783 82.1974339 14 80.4651283 8.65286198 85 5.34768916 79.3948678 5.34768916'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                    }
                                }
                            }
                            .--footer {
                                display: flex;
                                align-items: center;
                                gap: .5rem;
                                margin: .5rem 0 0;
                                > span {
                                    font-size: 1.2rem;
                                    font-weight: 100;
                                    &:last-child {
                                        color: color(gray, Gray-300);
                                        margin-left: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .catalog__product_media-gallery {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            .--image {
                background: color(gray, Gray-100);
                flex: 0 0 100%;
                &:not(:first-child) {
                    flex: 0 0 calc(50% - 1rem);
                }
                picture {
                    display: flex;
                    img {
                        aspect-ratio: 1;
                        object-fit: scale-down;
                        width: 100%;
                    }
                }
            }
            .--actions {
                margin: 2rem auto;
            }
            &--mobile {
                margin: 1rem 0 2rem;
                .swiper-slide {
                    align-self: center;
                    background: color(gray, Gray-100);
                }
                .swiper__progress {
                    margin: 1.5rem 0 0;
                }
            }
            + .--actions {
                display: flex;
                justify-content: center;
                margin: 2rem 0 0;
            }
        }
        .suitable__container {
            margin: 3rem 0 0;
            @include breakpoint(medium) {
                width: 60%;
            }
            @include breakpoint(small only) {
                margin: 2rem 0 0;
            }
            .--container {
                > span {
                    display: block;
                    font-size: 1.8rem;
                    line-height: 2rem;
                    margin: 0 0 1rem;
                }
                .--rating {
                    height: .8rem;
                    display: flex;
                    gap: 1rem;
                    > span {
                        flex: 1;
                        height: .8rem;
                        display: block;
                        background: color(gray, Gray-100);
                        &.--fill {
                            background: color(base, Secondary);
                        }
                    }
                }
                &:not(:last-child) {
                    margin: 0 0 1.5rem;
                }
            }
        }
        .usage__container {
            margin: 3rem 0 0;
            @include breakpoint(small only) {
                margin: 2rem 0 0;
            }
            .--container {
                display: flex;
                gap: 2rem;
                flex-wrap: wrap;
                @include breakpoint(small only) {
                    flex-flow: column;
                }
                > div {
                    flex: 1;
                    .--header {
                        height: 5.8rem;
                        display: flex;
                        justify-content: center;
                        align-items: flex-end;
                        margin: 0 0 1.5rem;
                        span {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='66px' height='80px' viewBox='0 0 66 80' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' fill='%23FFFFFF'%3E%3Cpolygon id='Path' points='-2.64017818e-17 -2.77881017e-16 -1.09912079e-14 36.1586914 33 80 66 36.1586914 66 -2.77881017e-16'%3E%3C/polygon%3E%3Cpath d='M60,6 L60,35.4005901 L32.95,71.3371779 L5.9,35.4005901 L5.9,6 L60,6 Z' id='Path' stroke='%23000000' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                            width: 6.6rem;
                            height: 8rem;
                            margin: 0 0 -.7rem 0;
                            display: flex;
                            justify-content: center;
                            font-family: $secondary-font;
                            text-transform: uppercase;
                            padding: 1.8rem 0;
                            font-size: 1.3rem;
                        }
                    }
                    > span {
                        text-transform: uppercase;
                        display: flex;
                        align-items: flex-start;
                        font-family: $secondary-font;
                        margin: 0 0 1.5rem;
                        @include breakpoint(small only) {
                            align-items: center;
                            justify-content: center;
                            &::before, &::after {
                                content: '';
                                height: .2rem;
                                background: color(base, Secondary);
                                flex: 1;
                            }
                        }
                    }
                    &:nth-child(1) {
                        .--header {
                            background: rgb(249, 196, 36);
                            background: linear-gradient(90deg, rgba(249, 196, 36, 1) 0%, rgba(138, 189, 147, 1) 100%);
                        }
                    }
                    &:nth-child(2) {
                        .--header {
                            background: rgb(138, 189, 147);
                            background: linear-gradient(90deg, rgba(138, 189, 147, 1) 0%, rgba(39, 134, 157, 1) 100%);
                        }
                    }
                    &:nth-child(3) {
                        .--header {
                            background: rgb(39, 134, 157);
                            background: linear-gradient(90deg, rgba(39, 134, 157, 1) 0%, rgba(49, 41, 99, 1) 100%);
                        }
                    }
                    p {
                        font-size: 1.4rem;
                        line-height: 2rem;
                        font-weight: 100;
                        text-align: justify;
                    }
                }
            }
        }
        .product__video {
            margin: 4rem auto 0;
            @include breakpoint(medium) {
                margin: 8rem auto 0;
            }
            @include breakpoint(medium) {
                max-width: 80%;
            }
        }
    }
    .sticky__add-to-cart-holder {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
        background: color(base, White);
        border-top: .1rem solid color(gray, Gray-100);
        filter: drop-shadow(0px 0px 5px color(gray, Gray-700));
        padding: 1rem;
        h4 {
            text-transform: uppercase;
            font-family: $secondary-font;
            line-height: normal;
            margin: 0 0 1rem;
        }
    }
    .off-canvas__container {
        height: 100%;
        .title {
            text-transform: none !important;
            font-size: 1.6rem !important;
            line-height: 2.1 !important;
            @include breakpoint(small only) {
                font-size: 1.4rem !important;
            }
        }
        > section {
            background: White;
            height: 100%;
            .product__information {
                padding: 0 0 1rem;
                background: color(gray, Gray-100);
                border-bottom: .1rem solid color(gray, Gray-200);
                section {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    img {
                        flex: 0 0 6.3rem;
                        aspect-ratio: 63/90;
                        object-fit: scale-down;
                    }
                    .--holder {
                        padding: 0 1rem 0 0;
                        h4 {
                            font-size: 1.6rem;
                            line-height: 2rem;
                            font-family: $default-font;
                            margin: 0;
                            text-transform: capitalize;
                        }
                        .price__holder {
                            display: flex;
                            gap: 1rem;
                            span {
                                font-size: 1.4rem;
                                line-height: 2.2rem;
                                font-weight: 900;
                                &.--old {
                                    text-decoration: line-through;
                                    color: color(gray, Gray-400);
                                    font-size: 1.2rem;
                                    @include breakpoint(large) {
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                        }
                    }
                }
                .--actions {
                    text-align: center;
                }
            }
        }
    }
    .catalog__product_media--slider {
        @include breakpoint(small only) {
            position: relative;
            height: 66vw;
            z-index: 1;
            &:not(.open-mobile) {
                img {
                    transform: none !important;
                }
            }
            &.open-mobile {
                position: fixed;
                height: 100%;
                z-index: 10;
                display: flex;
                align-items: center;
                .swiper-container {
                    width: 100%;
                }
                .close-media-slider {
                    display: block;
                    width: 40px;
                    height: 40px;
                }
            }


        }
            z-index: 10;
            display: flex;
            position: fixed;
            width: 100%;
            height: 100%;
            background: color(base, White);
            left: 0;
            top: 0;
        .swiper-slide {
            @include breakpoint(small only) {
                height: 66vw;
            }
            .image__container {
                height: 100%;
                .image__holder{
                    height: 100%;
                    display: flex;
                }
                img{
                    height: 90%;
                    width: auto;
                    margin: 2.5% auto;
                }
            }
        }

        .close-media-slider {
            width: 60px;
            height: 60px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23EFDD2D'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
            background-color: color(base, Black);
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 40px;
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;
            z-index: 2;

            @include breakpoint(small only) {
                display: none;
            }
            &:hover {
                background-color: color(base, SecondaryHover);
            }
        }
        .--swiper-pagination {
            &.panning {
                .next-media-slider,
                .prev-media-slider {
                    display: none;
                }
            }
            .next-media-slider,
            .prev-media-slider {
                position: absolute;
                top: calc(50%);
                width: 60px;
                height: 60px;
                background-color: color(base, Black);
                background-size: 50%;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 40px;
                cursor: pointer;
                z-index: 2;
                @include breakpoint(small only) {
                    top: calc(50% - 20px);
                    width: 40px;
                    height: 40px;
                }
                &.swiper-button-disabled {
                    display: none;
                }
            }
        }

        .next-media-slider,
        .prev-media-slider {
            transition: .3s all;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23EFDD2D'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
            &:hover{
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6' color='%23EFDD2D'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
            }
        }
        .next-media-slider {
            right: 12px;
            &:hover{
                transform: scale(1.1);
            }
        }
        .prev-media-slider {
            left: 12px;
            &:hover{
                transform: scale(1.1) rotate(180deg);
            }
        }
    }
}
